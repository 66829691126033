export const PUB_ADS_ID = 3;
export const KADAM_ID = 8;
export const EXO_CLICK_ID = 4;
export const TRAFFIC_STARTS_ID  = '9';
export const OCTO_CLICK = 6;
export const HILLTOP = '5';
export const UNDER_SITE = 7;
export const ALL = 'All';

export const ADVISERS_LIST = [
	{
		name: ALL,
		value: '',
	},
	{
		name: 'dsp.pubServ',
		network_slug: "pubads",
		value: PUB_ADS_ID,
	},
	{
		name: 'UnderSite',
		network_slug: "undersite",
		value: UNDER_SITE,
	},
	{
		name: 'ExoClick',
		network_slug: "exoclick",
		value: EXO_CLICK_ID,
	},
	{
		name: 'OctoClick',
		network_slug: "octoclick",
		value: OCTO_CLICK,
	},
	{
		name: 'Hilltop Ads',
		network_slug: "hilltop",
		value: HILLTOP
	}
]
